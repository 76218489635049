import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { parseISO } from "date-fns";

import { DotSeparatedList } from "../../components/DotSeparatedList";
import { Pill } from "../../components/Pill";
import { formatDateRangeDurationCompact } from "../../utils/formatDateRangeDurationCompact";

interface PriorityShiftPillProps {
  priorityUntilDatetimeString?: string;
  /**
   * If true, the pill will be compact and show a shorter label
   */
  compact?: boolean;
}

export function PriorityShiftPill(props: PriorityShiftPillProps) {
  const { compact = false, priorityUntilDatetimeString } = props;

  const timeLeft = isDefined(priorityUntilDatetimeString)
    ? formatDateRangeDurationCompact({
        startDate: new Date(),
        endDate: parseISO(priorityUntilDatetimeString),
      })
    : undefined;

  return (
    <Pill
      label={
        compact ? (
          timeLeft ?? "A Team"
        ) : (
          <Text semibold variant="body2">
            <DotSeparatedList>
              A Team
              {timeLeft}
            </DotSeparatedList>
          </Text>
        )
      }
      iconType="priority"
      color={(theme) => theme.palette.shifts?.priority.labelLight}
      labelColor={(theme) => theme.palette.shifts?.priority.labelDark}
    />
  );
}
