import { Box } from "@mui/material";

import { CalendarHeaderRow } from "../../Calendar/HeaderRow";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { LegacyOpenShiftTabSelector } from "./LegacyOpenShiftTabSelector";

export function ShiftDatePickerHeader() {
  return (
    <PageHeaderWrapper
      component="div"
      variant="tertiary"
      sx={(theme) => ({
        boxShadow: theme.shadows[3],
      })}
    >
      <Box sx={{ paddingBottom: 4, display: "flex", justifyContent: "center" }}>
        <LegacyOpenShiftTabSelector />
      </Box>

      <CalendarHeaderRow sx={{ px: 5 }} />
    </PageHeaderWrapper>
  );
}
