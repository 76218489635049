import { ButtonBase } from "@mui/material";

import { CbhIcon } from "../../components/CbhIcon";
import { DATE_CELL_MEDIUM_HEIGHT, DATE_CELL_MEDIUM_WIDTH } from "../../theming/components";

interface OpenShiftDatePickerButtonProps {
  onClick: () => void;
}

export function OpenShiftDatePickerButton(props: OpenShiftDatePickerButtonProps) {
  const { onClick } = props;
  return (
    <ButtonBase
      sx={{
        width: DATE_CELL_MEDIUM_WIDTH,
        height: DATE_CELL_MEDIUM_HEIGHT,
        backgroundColor: "transparent",
        flexShrink: 0,
        marginRight: 1,
      }}
      onClick={onClick}
    >
      <CbhIcon type="calendar-navigation" size="large" />
    </ButtonBase>
  );
}
