import { API_TYPES, type Offer, type OpenShiftWorkplace } from "./types";
import { type GetOpenShiftsResponse } from "./useGetOpenShifts";

export function getIncludedOpenShiftsData(data?: GetOpenShiftsResponse) {
  const offersMap = new Map<string, Offer>(
    ((data?.included ?? []) as Offer[])
      .filter((entity) => entity.type === API_TYPES.offer)
      .map((offer) => [offer.id, offer])
  );

  const workplacesMap = new Map<string, OpenShiftWorkplace>(
    ((data?.included ?? []) as OpenShiftWorkplace[])
      .filter((entity) => entity.type === API_TYPES.workplace)
      .map((workplace) => [workplace.id, workplace])
  );

  return {
    offersMap,
    workplacesMap,
  };
}
