import { CardActionArea, CardContent, Stack } from "@mui/material";
import { type DateRange } from "@src/appV2/lib/Calendar";
import { parseISO } from "date-fns";

import { ShiftCardDateTimeInfo } from "../Card/DateTimeInfo";
import { ShiftCardFacilityInfo } from "../Card/FacilityInfo";
import { ShiftCardPayInfoCard } from "../Card/PayInfoCard";
import { ShiftCardWrapper } from "../Card/Wrapper";
import type { Offer, OpenShift, OpenShiftWorkplace } from "../OpenShifts/types";
import { ShiftQualificationPill } from "../QualificationPill";
import { resolveShiftType } from "../resolveShiftType";
import { OpenShiftCardPills } from "./Pills";

interface OpenShiftCardProps {
  shift: OpenShift;
  workplace: OpenShiftWorkplace;
  offer: Offer;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function OpenShiftCard(props: OpenShiftCardProps) {
  const { shift, offer, workplace, onClick } = props;
  const { timeSlot, start, end, qualification, urgency, window, durationInHours, priorityTill } =
    shift.attributes;

  const shiftType = resolveShiftType({ urgency, window, priorityTill });

  const dateRange: DateRange = { startDate: parseISO(start), endDate: parseISO(end) };

  return (
    <ShiftCardWrapper shiftType={shiftType}>
      <CardActionArea sx={{ height: "100%" }} onClick={onClick}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 5,
            height: "100%",
          }}
        >
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={5}>
            <Stack spacing={5}>
              <OpenShiftCardPills shift={shift} shiftType={shiftType} />
              <ShiftCardDateTimeInfo
                dateRange={dateRange}
                timeSlot={timeSlot}
                durationInHours={durationInHours}
              />
            </Stack>

            <ShiftQualificationPill qualificationName={qualification} />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
            <ShiftCardFacilityInfo workplace={workplace} />

            <ShiftCardPayInfoCard
              offerPay={offer.attributes.pay.amountInMinorUnits / 100}
              finalPay={offer.attributes.pay.amountInMinorUnits / 100}
              originalAmount={durationInHours * (offer.attributes.pay.amountInMinorUnits / 100)}
            />
          </Stack>
        </CardContent>
      </CardActionArea>
    </ShiftCardWrapper>
  );
}
