import { Pill } from "../components/Pill";

export function InstantPayPill() {
  return (
    <Pill
      label="Instant Pay"
      labelColor={(theme) => theme.palette.instantPay?.text}
      color={(theme) => theme.palette.instantPay?.surface}
    />
  );
}
