import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";

import { SHIFT_DISCOVERY_SHIFT_MODAL_PATH } from "../../paths";
import { useShiftModalsDataContext } from "../../useShiftModalsDataContext";
import type { Offer, OpenShift, OpenShiftWorkplace } from "../OpenShifts/types";
import { OpenShiftCard } from "./Card";
import { OpenShiftsListLoadingState } from "./ListLoadingState";

interface OpenShiftsListProps {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  shifts: OpenShift[];
  workplacesMap: Map<string, OpenShiftWorkplace>;
  offersMap: Map<string, Offer>;
  loadingStateTexts: string[];
  renderEmptyState?: () => JSX.Element;
}

export function OpenShiftsList(props: OpenShiftsListProps) {
  const {
    isError,
    isLoading,
    isSuccess,
    shifts,
    workplacesMap,
    offersMap,
    loadingStateTexts,
    renderEmptyState,
  } = props;
  const { setOpenShift, navigateToModal } = useShiftModalsDataContext();

  const hasShifts = shifts.length > 0;

  if (isError) {
    return <Text>Error loading shifts</Text>;
  }

  if (isLoading) {
    return <OpenShiftsListLoadingState texts={loadingStateTexts} />;
  }

  if (isSuccess && !hasShifts) {
    if (isDefined(renderEmptyState)) {
      return renderEmptyState();
    }

    return null;
  }

  return (
    <Stack spacing={5}>
      {shifts?.map((shift) => {
        const offer = offersMap.get(shift.relationships.offer.data.id);
        const workplace = workplacesMap.get(shift.relationships.workplace.data.id);
        if (!offer || !workplace) {
          return null;
        }

        return (
          <OpenShiftCard
            key={shift.id}
            shift={shift}
            offer={offer}
            workplace={workplace}
            onClick={() => {
              setOpenShift(shift);
              navigateToModal(SHIFT_DISCOVERY_SHIFT_MODAL_PATH, { shiftId: shift.id });
            }}
          />
        );
      })}
    </Stack>
  );
}
