import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";
import { formatDistanceToNowStrict, parseISO } from "date-fns";

import { DotSeparatedList } from "../../components/DotSeparatedList";
import { Pill } from "../../components/Pill";
import { formatDayOfMonthWithDayName } from "../../utils/formatDayOfMonthWithDayName";
import { type ShiftWithType } from "../types";
import { WorkerShiftSummaryInfo } from "../WorkerShift/SumaryInfo";
import { ShiftCardWrapper } from "./Wrapper";

interface MyShiftCardProps {
  shift: ShiftWithType;
  outlined?: boolean;
}

export function MyShiftCard({ shift, outlined }: MyShiftCardProps) {
  const { start, type } = shift;
  const startDate = parseISO(start);

  return (
    <ShiftCardWrapper shiftType={type} sx={{ width: "100%", minHeight: 0 }} outlined={outlined}>
      <CardContent sx={{ px: 7 }}>
        <Stack direction="column" justifyContent="space-between" gap={7}>
          <Pill
            sx={{ width: "fit-content" }}
            label={
              <DotSeparatedList>
                <Text semibold variant="body2">
                  {formatDayOfMonthWithDayName(startDate)}
                </Text>
                <Text semibold variant="body2">
                  Starts in {formatDistanceToNowStrict(startDate)}
                </Text>
              </DotSeparatedList>
            }
          />

          <WorkerShiftSummaryInfo shift={shift} />
        </Stack>
      </CardContent>
    </ShiftCardWrapper>
  );
}
