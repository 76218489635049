import { mergeSxProps } from "@clipboard-health/ui-react";
import { type Theme } from "@mui/material";

import { Card, type CardProps } from "../../components/Card";
import { ShiftType } from "../constants";

interface ShiftCardWrapperProps extends CardProps {
  shiftType: ShiftType;
}

function resolveBorder(theme: Theme, shiftType: ShiftType) {
  if (shiftType === ShiftType.URGENT) {
    return `1px solid ${String(theme.palette.shifts?.urgent.border)}`;
  }

  return undefined;
}

export function ShiftCardWrapper(props: ShiftCardWrapperProps) {
  const { shiftType, variant = "tertiary", elevation = 2, sx, ...restProps } = props;

  return (
    <Card
      variant={variant}
      elevation={elevation}
      sx={mergeSxProps(
        (theme) => ({
          border: resolveBorder(theme, shiftType),
          minHeight: "12.875rem",
        }),
        sx
      )}
      {...restProps}
    />
  );
}
