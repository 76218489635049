import { Stack } from "@mui/material";

interface ShiftDateListWrapperProps {
  children: React.ReactNode;
}

export function ShiftDateListWrapper(props: ShiftDateListWrapperProps) {
  const { children } = props;

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        overflowX: "auto",
        scrollbarWidth: "none",
        paddingRight: 5,
        // Because overflowX is set to auto, overflowY is then set to auto/hidden.
        // This behavior is documented here: https://www.w3.org/TR/css-overflow-3/#valdef-overflow-auto
        // Adding paddingTop and negative marginTop fixes the issue.
        paddingTop: 3,
        marginTop: -3,

        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {children}
    </Stack>
  );
}
