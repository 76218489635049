import { Stack } from "@mui/material";

import { LoadingSpinner } from "../../components/LoadingSpinner";
import { LoadingTextAnimation } from "../../components/LoadingTextAnimation";

interface OpenShiftsListLoadingStateProps {
  texts: string[];
}
export function OpenShiftsListLoadingState(props: OpenShiftsListLoadingStateProps) {
  const { texts } = props;
  return (
    <Stack
      spacing={8}
      sx={{
        px: 8,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingSpinner size="large" variant="primary" />

      <LoadingTextAnimation semibold variant="h5" interval={4000} texts={texts} />
    </Stack>
  );
}
