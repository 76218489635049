import { Stack } from "@mui/material";
import { type DateRange } from "@src/appV2/lib/Calendar";

import { DateWithDuration } from "../../DateTime/DateWithDuration";
import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { type TimeSlot } from "../OpenShifts/types";
import { TimeSlotIcon } from "../TimeSlotIcon";

interface ShiftCardDateTimeInfoProps {
  dateRange: DateRange;
  timeSlot: TimeSlot;
  durationInHours: number;
}

export function ShiftCardDateTimeInfo(props: ShiftCardDateTimeInfoProps) {
  const { dateRange, timeSlot, durationInHours } = props;

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <TimeSlotIcon timeSlot={timeSlot} size="small" />
        <DateWithDuration
          startDate={dateRange.startDate}
          durationInHours={durationInHours}
          variant="body2"
          color={(theme) => theme.palette.text.secondary}
        />
      </Stack>

      <TimeRangeLabel semibold dateRange={dateRange} variant="h5" />
    </Stack>
  );
}
