import { isDefined } from "@clipboard-health/util-ts";
import { type DateRange } from "@src/appV2/lib";
import { useAgentShifts } from "@src/appV2/OpenShifts/api/useAgentShifts";
import { useOpenShiftCount } from "@src/appV2/OpenShifts/api/useOpenShiftCount";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useState } from "react";

import { useShiftDiscoveryUserFiltersContext } from "../../Filters/useUserFiltersContext";
import { groupShiftsByDateAndTimeSlot } from "../../utils/groupShiftsByDateAndTimeSlot";

interface UseShiftDiscoveryCalendarDataProps {
  initialDateRange: DateRange;
}

export const useShiftDiscoveryCalendarData = (props: UseShiftDiscoveryCalendarDataProps) => {
  const { initialDateRange } = props;
  const worker = useDefinedWorker();

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { distance, license } = useShiftDiscoveryUserFiltersContext();

  const [dateRange, setDateRange] = useState<DateRange>(initialDateRange);

  const {
    data: workerShiftsData,
    isSuccess: workerShiftsIsSuccess,
    isLoading: workerShiftsIsLoading,
    refetch: refetchWorkerShifts,
  } = useAgentShifts({
    startDate: dateRange?.startDate,
    endDate: dateRange?.endDate,
    groupByDate: false,
    tmz: timezone ?? "",
  });

  const workerShiftsByDate = workerShiftsIsSuccess
    ? groupShiftsByDateAndTimeSlot(workerShiftsData.agentShifts ?? [], timezone)
    : {};

  const {
    data: openShiftsCountData,
    isLoading: openShiftsCountIsLoading,
    refetch: refetchOpenShiftsCount,
    // TODO: count with useOpenShifts instead to take filters into account, otherwise it's not accurate
  } = useOpenShiftCount(
    {
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      qualification: license,
      coordinates: worker.geoLocation?.coordinates,
      specialities: {
        hasSedationExperience: worker.specialities?.hasSedationExperience ?? false,
        hasTrayAssemblyExperience: worker.specialities?.hasTrayAssemblyExperience ?? false,
      },
      distance,
      tmz: timezone ?? "",
      isAgent: true,
    },
    {
      enabled: isDefined(timezone) && isDefined(worker?.preference),
    }
  );

  const refetch = () => {
    void refetchWorkerShifts();
    void refetchOpenShiftsCount();
  };

  return {
    dateRange,
    setDateRange,
    workerShiftsByDate,
    workerShiftsIsLoading,
    openShiftsCountData,
    openShiftsCountIsLoading,
    refetch,
  };
};
