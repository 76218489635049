import { Stack } from "@mui/material";

import { ShiftDocumentsPill } from "../Bookability/Documents/Pill";
import { ShiftType } from "../constants";
import type { OpenShift } from "../OpenShifts/types";
import { PriorityShiftPill } from "../Priority/Pill";
import { UrgentShiftPill } from "../Urgent/Pill";

interface OpenShiftCardPillsProps {
  shift: OpenShift;
  shiftType: ShiftType;
  missingDocumentsCount?: number;
}

export function OpenShiftCardPills(props: OpenShiftCardPillsProps) {
  const { shift, missingDocumentsCount = 0, shiftType } = props;

  const showUrgentPill = shiftType === ShiftType.URGENT;
  const showPriorityPill = shiftType === ShiftType.PRIORITY;
  const showMissingDocumentsPill = missingDocumentsCount > 0;

  const pillsCount = [showUrgentPill, showPriorityPill, showMissingDocumentsPill].filter(
    Boolean
  ).length;

  const hasMultiplePills = pillsCount > 1;

  if (!pillsCount) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={5}>
      {showUrgentPill && <UrgentShiftPill compact={hasMultiplePills} />}

      {showPriorityPill && (
        <PriorityShiftPill
          priorityUntilDatetimeString={shift.attributes.priorityTill}
          compact={hasMultiplePills}
        />
      )}

      {showMissingDocumentsPill && (
        <ShiftDocumentsPill
          missingDocumentsCount={missingDocumentsCount}
          compact={hasMultiplePills}
        />
      )}
    </Stack>
  );
}
