import {
  type GetOpenShiftsRequestQuery,
  getOpenShiftsRequestQuery,
  type GetOpenShiftsResponse,
  getOpenShiftsResponse,
} from "@clipboard-health/contract-worker-app-bff";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns";

export {
  type GetOpenShiftsRequestQuery,
  type GetOpenShiftsResponse,
} from "@clipboard-health/contract-worker-app-bff";

export const GET_OPEN_SHIFTS_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/open-shifts`;

export function useGetOpenShifts(
  query: GetOpenShiftsRequestQuery,
  options: UseGetQueryOptions<GetOpenShiftsResponse> = {}
): UseQueryResult<GetOpenShiftsResponse> {
  return useGetQuery<GetOpenShiftsRequestQuery, GetOpenShiftsResponse>({
    url: GET_OPEN_SHIFTS_URL,
    queryParams: query,
    requestSchema: getOpenShiftsRequestQuery,
    responseSchema: getOpenShiftsResponse,
    meta: {
      userErrorMessage: "Something went wrong while loading shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_LIST_VIEW_OPEN_SHIFTS_FAILURE,
    },
    // We don't want to refetch open shifts frequently as it can cause bad UX and performance issues.
    staleTime: minutesToMilliseconds(5),
    refetchOnMount: false,
    ...options,
  });
}
